import React from "react";

const IframeComponent = ({ src, title }) => (
  <div className="__learn-video-wrapper">
    <iframe
      className="__learn-iframe"
      src={src}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      loading="lazy"
    ></iframe>
  </div>
);

export default IframeComponent;
